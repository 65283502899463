import { Route } from "react-router-dom";
import Stripe from "@components/Stripe";
import NotFound from "@components/NotFound";

export default [
  {
    type: Route,
    path: ["/payments/:id"],
    component: Stripe,
    exact: true,
    state: "checkoutPage",
  },
  {
    type: Route,
    path: ["*"],
    component: NotFound,
    exact: true,
    state: "notFoundPage",
  },
];
