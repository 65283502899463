import React from "react";
import PaymentForm from "@components/PaymentForm";
import PaymentInfo from "@components/PaymentInfo";
import { usePayment } from "@hooks";
import "./index.scss";

export default () => {
  const [payment, status, setStatus] = usePayment();
  console.log(payment, "payment");
  switch (status.type) {
    case "not_paid":
      return (
        <div className="form-container">
          <PaymentForm payment={payment} setStatus={setStatus} />
        </div>
      );
    case "paid":
      return (
        <div className="paid">
          {/* <div>Your payment was successfully processed</div> */}
          <PaymentInfo payment={payment} message={"Your payment was successfully processed"} />
        </div>
      );
    case "refunded":
      return (
        <div className="refunded">
          {/* <div>Your payment was successfully refunded</div> */}
          <PaymentInfo payment={payment} type={status.type} message={"Your payment was successfully refunded"} />
        </div>
      );
    case "error":
      // return <div className="error">{status.message}</div>;
      return <PaymentInfo payment={payment} message={status.message} error={true} />;
    default:
      return <div className="loader">Loading</div>;
  }
};
