import React, { useState, useRef } from "react";
import { CardElement, useElements, useStripe, StripeCheckout, CardNumberElement, CardExpiryElement, CardCvcElement } from "@stripe/react-stripe-js";
import "./index.scss";
import PaymentInfo from "@components/PaymentInfo";

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      fontFamily: 'Roboto, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "14px",
      padding: "20px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

export default ({ payment, setStatus }) => {
  const stripe = useStripe();
  const elements = useElements();

  const textInput = useRef(null);
  const [paymentForm, setPaymentForm] = useState({
    name: payment.fullName || "",
    locationName: payment.location.name || "",
    zipCode: "",
  });

  const [paymentFormError, setPaymentFormError] = useState({
    card: false,
    name: false,
  });

  const [processing, setProcessing] = useState(false);

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    setPaymentFormError({
      card: elements._elements[0]._empty,
      name: !paymentForm.name,
    })

    if (!paymentForm.name) {
      return setPaymentFormError({ ...paymentFormError, name: true })
    }
    if (elements._elements[0]._empty) {
      return;
    }

    if (!stripe || !elements || !payment) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    //TODO: perform your form validation code here

    setProcessing(true);
    try {
      const result = await stripe.confirmCardPayment(payment.clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name: paymentForm.name,
          },
        },
      });

      if (result.error) {
        // Show error to your customer (e.g., insufficient funds)
        setStatus({
          type: "error",
          message: result.error.message,
        });
      } else {
        // The payment has been processed!
        if (result.paymentIntent.status === "succeeded") {
          setStatus({
            type: "paid",
            message: null,
          });
        }
      }
    } catch (e) {
      setStatus({
        type: "error",
        message: "Unknown error occurred",
      });
    } finally {
      setProcessing(false);
    }
  };
  return (
    <div className='card-data-form'>

      <form onSubmit={handleSubmit}>
        {/* <div className='svg-close'>
          <svg
            data-name="Group 905"
            width={15}
            height={15}
            viewBox="0 0 15 15"
          >
            <path
              data-name="Path 931"
              d="M9.283 7.5l5.348-5.348A1.261 1.261 0 1012.847.37L7.5 5.717 2.152.369A1.261 1.261 0 10.369 2.152L5.717 7.5.37 12.847a1.261 1.261 0 101.783 1.783l5.348-5.348 5.346 5.349a1.261 1.261 0 101.784-1.784z"
              fill="#a3a6be"
              fillRule="evenodd"
            />
          </svg>
        </div> */}
        <div className='amount-field'>
          <label> Amount due</label>
          <span> ${Number(payment.amount).toFixed(2)} </span>
        </div>
        <div className="user-name">
          <div >
            <label>Name</label>
          </div>
          <input
            onChange={(e) =>
              setPaymentForm({
                ...paymentForm,
                name: e.target.value,
              })
            }
            value={paymentForm.name || ""}
          />
          {paymentFormError.name ? <div className={"card-error"}>
            Name  is required
                 </div> : null}
        </div>
        <div className="card-label-item">
          <div className='card-item-field' >
            <label> Card number</label>
            <CardElement options={CARD_ELEMENT_OPTIONS} className="card-field" />
            {paymentFormError.card ? <div className={"card-error"}>
              Card Element is required
                 </div> : null}
          </div>

        </div>
        <div className="user-name">
          <div >
            <label >Location Name</label>
          </div>
          <label className='user-location-name'>{paymentForm.locationName} </label>
        </div>
        {/* <PaymentInfo payment={payment} /> */}
        <button className='payments-btn' disabled={!stripe || processing}>Pay ${Number(payment.amount).toFixed(2)}</button>
      </form>

    </div>
  );
};
