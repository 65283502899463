import { useState, useEffect } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { fetchPayment } from "@actions/payment";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const usePayment = () => {
  const history = useHistory();
  const query = useQuery();

  const [payment, setPayment] = useState(null);
  const [status, setStatus] = useState({
    type: "loading",
    message: null,
  });
  const { id: paymentId } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchPayment(paymentId, query.get("token"));
        setPayment(response.data);
        setStatus({
          type:
            response.data.status === "rejected"
              ? "error"
              : response.data.status,
          message:
            response.data.status === "rejected"
              ? "Payment processing failed"
              : null,
        });
      } catch (e) {
        if (e.response.status === 404) {
          history.push("/404");
          return;
        }
        setStatus({
          type: "error",
          message: e.message,
        });
        // setPayment({
        //   fullName: "test",
        //   amount: 100,
        //   location: {
        //     name: "test"
        //   }
        // })
        // setStatus({
        //   type: "paid",
        //   message: "",
        // })
       
      }
    };
    fetchData();
  }, [history, paymentId]);

  return [payment, status, setStatus];
};
