import React from "react";
import "./index.scss";

export default ({ payment, error, message, type }) => {
  return (
    <div className={`card-data-form`} >
      <div className={`${error ? " card-data-error " : " "} payment-field `}>
        <div className='payment-logo'>
          {(type || error) ? <SvgErrorLogo color={type ? "#FFDE5A" : ""} /> : <SvgLogo />}
        </div>
        <div className="payment-message">
          {message}
        </div>
        {!error ? <div className="payment-amount">
          ${payment.amount}
        </div> : null}
        {!error ? <div className="payment-message">
          {payment.location.name}
        </div> : null}
      </div>
    </div>
  )


  // return (
  //   <div className="payment-info-container">
  //     <div>{payment.location.name}</div>
  //     <div>Amount due ${payment.amount}</div>
  //     <div>
  //       By using this service, you agree to be bound to Matador{" "}
  //       <a
  //         target="_blank"
  //         rel="noopener noreferrer"
  //         href="https://matador.ai/privacy.html"
  //       >
  //         Privacy Policy
  //       </a>
  //     </div>
  //   </div>
  // );
};



export const SvgLogo = (props) => {
  return (
    <svg width={127} height={127} viewBox="0 0 127 127" >
      <g data-name="Group 8467">
        <g data-name="Group 8345" transform="translate(-897 -278)">
          <circle
            data-name="Ellipse 672"
            cx={63.5}
            cy={63.5}
            r={63.5}
            transform="translate(897 278)"
            fill="#00cb85"
            opacity={0.02}
          />
          <circle
            data-name="Ellipse 673"
            cx={49.5}
            cy={49.5}
            r={49.5}
            transform="translate(911 292)"
            fill="#00cb85"
            opacity={0.04}
          />
          <path
            data-name="Path 6063"
            d="M960.5 306c19.606 0 35.5 35.5 35.5 35.5l-9.489 16.472S970.852 377 960.5 377a35.5 35.5 0 010-71z"
            fill="#00cb85"
            opacity={0.06}
          />
          <g data-name="Group 1676">
            <path
              data-name="Path 1464"
              d="M936.478 323.766l22.834-11.499a2.379 2.379 0 012.19 0l22.942 11.609a2.56 2.56 0 011.369 2.135v24.695c0 6.078-2.574 11.17-7.009 15.605-4.27 4.217-10.239 7.721-17.466 10.789a2.212 2.212 0 01-1.862 0c-7.228-3.066-13.2-6.571-17.467-10.787-4.435-4.437-7.009-9.529-7.009-15.607v-24.695a2.465 2.465 0 011.478-2.245zm23.928-6.68l-20.587 10.404v23.216c0 4.654 2.026 8.651 5.585 12.156 3.614 3.614 8.761 6.68 15 9.418 6.187-2.738 11.389-5.8 14.948-9.418 3.559-3.5 5.585-7.5 5.585-12.156V327.49l-20.53-10.404z"
              fill="#00cb85"
            />
          </g>
        </g>
        <path
          data-name="Icon awesome-check"
          d="M60.793 73.705l-6.5-6.537a1.01 1.01 0 010-1.422l1.414-1.424a1 1 0 011.414 0l4.379 4.4 4.357-4.382 5.022-5.051a1 1 0 011.414 0l1.414 1.422a1.01 1.01 0 010 1.422l-11.5 11.572a1 1 0 01-1.414 0z"
          fill="#00cb85"
        />
      </g>
    </svg>
  )
}


export const SvgErrorLogo = (props) => {
  return (
    <svg width={127} height={127} viewBox="0 0 127 127" {...props}>
      <g data-name="Group 8340" transform="translate(-897 -278)">
        <circle
          data-name="Ellipse 672"
          cx={63.5}
          cy={63.5}
          r={63.5}
          transform="translate(897 278)"
          fill={props.color ? props.color : "#ff7366"}
          // fill="#ff7366"
          opacity={0.02}
        />
        <circle
          data-name="Ellipse 673"
          cx={49.5}
          cy={49.5}
          r={49.5}
          transform="translate(911 292)"
          fill={props.color ? props.color : "#ff7366"}
          // fill="#ff7366"
          opacity={0.04}
        />
        <path
          data-name="Path 6064"
          d="M960.5 303a35.5 35.5 0 11-35.5 35.5 35.5 35.5 0 0135.5-35.5z"
          // fill="#ff7366"
          fill={props.color ? props.color : "#ff7366"}
          opacity={0.06}
        />
        <g data-name="Group 1676">
          <path
            data-name="Path 1464"
            d="M936.478 323.71l22.834-11.499a2.379 2.379 0 012.19 0l22.942 11.609a2.56 2.56 0 011.369 2.135v24.695c0 6.078-2.574 11.17-7.009 15.605-4.27 4.217-10.239 7.721-17.466 10.789a2.212 2.212 0 01-1.862 0c-7.228-3.066-13.2-6.571-17.467-10.787-4.435-4.437-7.009-9.529-7.009-15.607v-24.695a2.465 2.465 0 011.478-2.245zm23.929 27.542a3.614 3.614 0 11-3.614 3.614 3.61 3.61 0 013.614-3.614zm0-3.614a2.517 2.517 0 01-2.407-2.464l-1.2-10.842c-.219-1.916 1.752-3.669 3.614-3.669s3.833 1.752 3.614 3.669l-1.2 10.842a2.517 2.517 0 01-2.409 2.464zm0-30.608l-20.588 10.404v23.216c0 4.654 2.026 8.651 5.585 12.156 3.614 3.614 8.761 6.68 15 9.418 6.187-2.738 11.389-5.8 14.948-9.418 3.559-3.5 5.585-7.5 5.585-12.156v-23.216l-20.53-10.404z"
            // fill="#ff7366"
            fill={props.color ? props.color : "#ff7366"}
          />
        </g>
      </g>
    </svg>
  )
}
