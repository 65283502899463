import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Checkout from "@components/Checkout";
import "./index.scss";
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

export default () => {
  return (
    <Elements stripe={stripePromise}>
      <div className="payment-wrapper-card">
        <div className='modal-logo'>
          <label>
            <svg width={125} height={28.91} viewBox="0 0 125 28.91" >
              <g fill="#2d2d66">
                <path d="M72.47 22.196v2.2a9.457 9.457 0 01-2.151.272c-2.694 0-4.474-1.285-4.474-4.227v-5.932h-1.978V12.26h1.978V8.815h2.62v3.448h3.609v2.249h-3.609v5.703c0 1.607.89 2.1 2.4 2.1a14.585 14.585 0 001.605-.119zM47.224 7.81v16.586h-2.62V14.163l-4.672 6.13h-.89l-4.7-6.155v10.258h-2.62V7.81h.914l6.847 9.121L46.33 7.81zM62.012 12.259v12.136h-2.521v-1.557a5.218 5.218 0 01-4.153 1.829c-3.436 0-5.932-2.719-5.932-6.328s2.472-6.354 5.932-6.354a5.089 5.089 0 014.251 2v-1.73zm-2.571 6.081a3.616 3.616 0 00-3.658-3.98 3.975 3.975 0 000 7.935 3.608 3.608 0 003.659-3.955zM86.023 12.259v12.136h-2.521v-1.557a5.218 5.218 0 01-4.153 1.829c-3.436 0-5.932-2.719-5.932-6.328s2.468-6.354 5.932-6.354a5.089 5.089 0 014.252 2v-1.73zm-2.571 6.081a3.616 3.616 0 00-3.658-3.98 3.975 3.975 0 000 7.935 3.608 3.608 0 003.658-3.955z" />
                <path d="M86.023 12.259v12.136h-2.521v-1.557a5.218 5.218 0 01-4.153 1.829c-3.436 0-5.932-2.719-5.932-6.328s2.468-6.354 5.932-6.354a5.089 5.089 0 014.252 2v-1.73zm-2.571 6.081a3.616 3.616 0 00-3.658-3.98 3.975 3.975 0 000 7.935 3.608 3.608 0 003.658-3.955zM100.837 8.011v16.384h-2.422v-1.73a5.088 5.088 0 01-4.251 2c-3.461 0-5.932-2.719-5.932-6.328s2.5-6.353 5.932-6.353a5.148 5.148 0 014.054 1.755V8.011zM98.266 18.34a3.63 3.63 0 00-3.658-3.98 3.975 3.975 0 000 7.935 3.6 3.6 0 003.659-3.955zM103.018 18.315a6.427 6.427 0 116.4 6.353 6.294 6.294 0 01-6.4-6.353zm10.184.025a3.775 3.775 0 10-7.539 0 3.773 3.773 0 107.539 0zM125 12.26v2.3h-.791a3.254 3.254 0 00-3.559 3.362v6.476h-2.6V12.26h2.422v1.582a4.413 4.413 0 013.535-1.706 3.577 3.577 0 01.993.124z" />
              </g>
              <path
                d="M24.484.153a.338.338 0 00-.584.033v.006a.333.333 0 00-.016.26 5.441 5.441 0 01-5.088 7.212H6.963A5.441 5.441 0 011.874.455a.342.342 0 00-.015-.263V.186a.336.336 0 00-.58-.037A7.529 7.529 0 000 4.354a7.576 7.576 0 005.134 7.167 9.813 9.813 0 00.822 12.946L9.79 28.3a2.076 2.076 0 001.468.608h3.278a2.076 2.076 0 001.468-.609l3.834-3.835a9.813 9.813 0 00.813-12.956 7.572 7.572 0 005.1-7.156A7.528 7.528 0 0024.484.153zM16.94 21.4l-4.34 4.464v-2.73a5.59 5.59 0 01-3.64-1.589l-.1-.095a5.61 5.61 0 119.624-3.92 5.53 5.53 0 01-1.544 3.87z"
                fill="#706aff"
              />
            </svg>
          </label>
        </div>
        <Checkout />
        <div className='privacy-policy-field'>
          By using this service, you agree to be bound to Matador{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://matador.ai/privacy.html"
          >
            Privacy Policy
          </a>
        </div>
      </div>

    </Elements>
  );
};
