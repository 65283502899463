import React from "react";
import "./index.scss";
import { SvgErrorLogo } from "../PaymentInfo";

export default () => {
  return (
    <div className={`card-data-form`} >
      <div className={` card-data-error payment-field `}>
        <div className='payment-logo'>
          <SvgErrorLogo />
        </div>
        <div className="payment-message">
          Not found
      </div>
      </div>
    </div>
  );
  // return <div>Not found</div>;
};
