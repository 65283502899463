import React from "react";
import { Router as BrowserRouter, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import routes from "./routes/index";
import "~styles/index.scss";

const newHistory = createBrowserHistory();

export default (props) => (
  <>
    <BrowserRouter history={newHistory}>
      <Switch>
        {routes.map((route, index) => (
          <route.type
            key={index}
            exact={route.exact}
            path={route.path}
            component={() => <route.component />}
            location={route.location}
          />
        ))}
      </Switch>
    </BrowserRouter>
  </>
);
